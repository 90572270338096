import * as React from 'react';
import { useForm } from 'hooks/useForm';
import emailjs from '@emailjs/browser';
import { init } from '@emailjs/browser';
import { FaSpinner, FaCheck } from 'react-icons/fa';
import { FormattedMessage, useIntl } from 'react-intl';
init("user_c2Pm1FXs32XHkdboahfVI");


const Contact = () => {

  const intl = useIntl();

  const [submitted, setSubmitted] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [succes, setSuccess] = React.useState(false);

  const initialState = {
    name: '',
    phone: '',
    email: '',
    message: ''
  };

  // getting the event handlers from our custom hook
  const { onChange, onSubmit, values } = useForm(
    loginUserCallback,
    initialState
  );

  // a submit function that will execute upon form submission
  async function loginUserCallback() {
    // send "values" to database
    setSubmitted(true);
    if (values.name != '' && values.email != '' && values.message != '') {
      setLoading(true);
      emailjs.send('service_tog9uv1', 'template_3b2e63c', values)
        .then((result) => {
          console.log(result.text);
          setLoading(false);
          setSuccess(true);
        }, (error) => {
          console.log(error.text);
          setLoading(false);
        });
    }
  }

  return (
    <section className="contact-sec" id="contact">
      <div className="container expand-container">
        <div className="row">
          <div className="col-12 col-lg-6 px-lg-0 section6left wow slideInLeft" data-wow-delay=".5s">
            <div className="heading-area">
              <h2 className="heading text-center text-md-left"><FormattedMessage id="contact.contactUs" defaultMessage="Contact us" /></h2>
            </div>
            <form className="row contact-form" id="contact-form-data" autoComplete="off" onSubmit={onSubmit} noValidate>
              <div className="col-12 p-0" id="result"></div>
              <input type="text" name="name" placeholder={intl.formatMessage({ id: 'contact.name', defaultMessage: 'Name' })} className="form-control" onChange={onChange} required />
              {submitted && <span className="error">{values.name == '' ? <FormattedMessage id="contact.nameRequired" defaultMessage="Name required" /> : ''}</span>}
              <input type="text" name="phone" placeholder={intl.formatMessage({ id: 'contact.contactNo', defaultMessage: 'Contact number' })} className="form-control" onChange={onChange} />
              <input type="email" name="email" placeholder={intl.formatMessage({ id: 'contact.email', defaultMessage: 'Email' })} className="form-control" autoComplete="false" onChange={onChange} required />
              {submitted && <span className="error">{values.email == '' ? <FormattedMessage id="contact.emailRequired" defaultMessage="Email required" /> : ''}</span>}
              <textarea className="form-control" name="message" rows={6} placeholder={intl.formatMessage({ id: 'contact.message', defaultMessage: 'Type Your Message Here' })} onChange={onChange} required ></textarea>
              {submitted && <span className="error">{values.message == '' ? <FormattedMessage id="contact.messageRequired" defaultMessage="Message required" /> : ''}</span>}
              <button disabled={loading || succes} type="submit" className={`btn btn-medium btn-rounded btn-orange rounded-pill w-100 contact_btn main-font ${succes ? "succes" : ""}`}>
                {!loading && !succes && <span><FormattedMessage id="contact.submitInformation" defaultMessage="Submit Information" /></span>}
                {loading && !succes && <FaSpinner className="spinner" />}
                {!loading && succes && <span><FaCheck style={{ margin: '-3px 5px 0px 0px' }} /> <FormattedMessage id="contact.submitted" defaultMessage="Submitted" /> </span>}
              </button>
            </form>
          </div>
          <div className="col-12 col-lg-6 text-center img-center wow fadeIn" data-wow-delay="1s">
            <div className="contact-img">
              {/* <img src="product-dark/img/contact-product.png" alt="Image"> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Contact

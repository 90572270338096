/*
  https://www.gatsbyjs.com/docs/reference/release-notes/image-migration-guide/
  https://www.gatsbyjs.com/plugins/gatsby-plugin-image/
  https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-plugin-image/
*/
import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';
import { FormattedMessage } from 'react-intl';

const Banner = () => {
    const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "streamline-logo.webp" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
    }
  `);

  return (
    <section id="home" style={{height: '100%', width: '100%'}}>
      <div className="banner-text-wrapper">
        <h1 className="banner-text">
          <span className="text-orange"><FormattedMessage id="home.firstLine" defaultMessage="Our mission" /></span> <br/>
          <span><FormattedMessage id="home.secondLine" defaultMessage="StreamLINE your IT needs" /></span>
        </h1>
      </div>
      <div className="banner"></div>
      <GatsbyImage
        className="banner-logo"
        image={data.file.childImageSharp.gatsbyImageData}
        alt="streamline-logo" />
    </section>
  )
}

export default Banner

import * as React from "react"
import { FormattedMessage } from "react-intl"


const About = () => {

  return (
    <section className="portfolio" id="portfolio">
      <div className="container">
        <div className="row no-gutters">
          <div className="col-12 col-md-6 col-lg-6 wow fadeInLeft" data-wow-delay="250ms" data-wow-duration="1s">
            <div className="cover">
              <div className="card">
                <h1 className="numbering text-green">01.</h1>
              </div>
              <div className="card-body">
                <h5 className="card-title"><FormattedMessage id="about.developement" defaultMessage="Custom Application Development" /></h5>
                <p className="card-text info"><FormattedMessage id="about.developementDescription" defaultMessage="Crafting bespoke applications tailored to your business needs, powered by the latest technologies." /></p>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6 col-lg-6 wow fadeInUp" data-wow-delay="250ms" data-wow-duration="1s">
            <div className="cover">
              <div className="card">
                <h1 className="numbering">02.</h1>
              </div>
              <div className="card-body">
                <h5 className="card-title"><FormattedMessage id="about.itManagement" defaultMessage="IT Platform Management" /></h5>
                <p className="card-text info"><FormattedMessage id="about.itManagementDescription" defaultMessage="End-to-end management of your entire IT infrastructure, encompassing servers, firewalls, client computers, and more. Whether on-premise, cloud-based, or hybrid configurations." /></p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-6  wow fadeInRight" data-wow-delay="250ms" data-wow-duration="1s">
            <div className="cover">
              <div className="card">
                <h1 className="numbering">03.</h1>
              </div>
              <div className="card-body">
                <h5 className="card-title"><FormattedMessage id="about.security" defaultMessage="Security Audits and Consultation" /></h5>
                <p className="card-text info"><FormattedMessage id="about.securityDescription" defaultMessage="Upon request, we conduct thorough security assessments of your IT architecture, encompassing penetration testing, vulnerability scanning, and more. Our detailed reports provide actionable insights to bolster your defenses. Coupled with expert advice, we guide you in fortifying your overall security posture." /></p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-6 wow fadeInLeft" data-wow-delay="250ms" data-wow-duration="1s">
            <div className="cover">
              <div className="card">
                <h1 className="numbering text-green">04.</h1>
              </div>
              <div className="card-body">
                <h5 className="card-title"><FormattedMessage id="about.dataIntegration" defaultMessage="Data Integration and Migration" /></h5>
                <p className="card-text info"><FormattedMessage id="about.dataIntegrationDescription" defaultMessage="With extensive experience in data migration, we specialize in creating bridges between systems not inherently designed to communicate with each other. This ensures seamless integration and transition across disparate platforms. Beyond mere migration, our expertise also spans big data and business intelligence, empowering you to harness the untapped potential and richness of your data assets." /></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section >
  )
}

export default About

import { useState } from "react";

// export interface ContactForm {
//   name: string;
//   phone: string;
//   email: string;
//   message: string;
// }
// useForm functional componen
export const useForm = (callback: any, initialState: any) => {
  const [values, setValues] = useState(initialState);

  // onChange
  const onChange = (event: React.ChangeEvent<any>) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  // onSubmit
  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await callback(); // triggering the callback
  };

  // return values
  return {
    onChange,
    onSubmit,
    values,
  };
}

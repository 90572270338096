import * as React from "react"

import introVideo from "assets/images/logo_black_new.mp4"


const Loader = () => {
    setTimeout(function() {
        document.getElementById("loader-bg").style.display = "none";
    }, 2900)

    return (
        <div className="loader-bg" id="loader-bg">
            <div className="svg-loader">
                <video playsInline autoPlay className="svg-loader-video"  muted>
                    <source src={introVideo} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
        </div>
    )
}

export default Loader

import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image"
import * as React from "react"
import { FormattedMessage } from "react-intl";


const Products = () => {

  const data = useStaticQuery(graphql`
  query {
    img1: file(relativePath: { eq: "streamos.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    },
    img2: file(relativePath: { eq: "streamlab.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    },
    img3: file(relativePath: { eq: "streamcal.webp" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    },
    img4: file(relativePath: { eq: "streamvoice.webp" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    },
    img5: file(relativePath: { eq: "streamgo.webp" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
  }
`);

  return (
    <section className="products" id="products">
      <div className="blog-content">
        <div className="container">
          <div className="col-12">
            <div className="standalone-detail">
              <div className="row no-gutters">
                <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2  text-center wow slideInUp" data-wow-duration="2s">
                  <h1 className="heading text-white">
                    <span className="text-orange" style={{ marginRight: '10px' }}>
                      <FormattedMessage id="products.our" defaultMessage="Our" />
                    </span>
                    <FormattedMessage id="products.products" defaultMessage="Products" />
                  </h1>
                </div>
              </div>
            </div>

            <div className="standalone-area">
              <div className="row standalone-row align-items-center no-gutters">
                <div className="col-lg-6">
                  <div className=" wow hover-effect fadeInLeft image">
                    <GatsbyImage
                      className="img"
                      image={data.img1.childImageSharp.gatsbyImageData}
                      alt="product1-image" />
                  </div>
                </div>
                <div className="col-lg-6 stand-img-des">
                  <div className="container d-inline-block">
                    <p className="sub-heading text-center text-orange"><FormattedMessage id="products.streamOS" defaultMessage="StreamOS" /></p>
                    <h2 className="heading-text text-white">
                      <div><FormattedMessage id="products.streamOSTitleLine1" defaultMessage="Command, Control, Conquer" /></div>
                      <div><FormattedMessage id="products.streamOSTitleLine2" defaultMessage="The Future of Unified IT Management" /></div>
                    </h2>
                    <p className="card-text mt-5"><FormattedMessage id="products.streamOS_1" defaultMessage="StreamOS is an innovative Information Technology as a Service (ITaaS) solution designed to cater to the dynamic needs of modern businesses. Built upon a minimalist Ubuntu LTS (Long-Term Support) platform complemented by an XFCE desktop environment, StreamOS delivers a Virtual Desktop Infrastructure (VDI) optimized for user-friendly interactions and low system requirements." /></p>
                    <p className="card-text mt-1"><FormattedMessage id="products.streamOS_2" defaultMessage="StreamOS integrates the power of the ZFS file system, offering strong encryption and guaranteeing high data integrity. This sophisticated system, coupled with our centralized web management console, allows administrators to remotely generate and restore snapshots as required, offering a robust shield against data corruption." /></p>
                    <p className="card-text mt-1"><FormattedMessage id="products.streamOS_3" defaultMessage="For secure and swift connectivity, StreamOS employs WireGuard VPN. Known for its optimized codebase and state-of-the-art cryptography, WireGuard creates super-fast, secure VPN tunnels. StreamOS has further built upon this by implementing an automation layer for seamless WireGuard tunnel deployment, thereby minimizing the potential for human error and significantly bolstering security." /></p>
                    <p className="card-text mt-1"><FormattedMessage id="products.streamOS_4" defaultMessage="What makes StreamOS stand out is its user-friendly installation process. Users can simply boot from a USB drive and follow a guided process. The installer also includes a remote access feature, allowing an administrator to guide users through the installation process remotely. StreamOS can be installed on desktops or laptops, even on removable drives with persistent storage, offering flexibility and promoting a secure Bring Your Own Device (BYOD) policy." /></p>
                    <p className="card-text mt-1"><FormattedMessage id="products.streamOS_5" defaultMessage="At the core of StreamOS lies a centralized web console equipped with comprehensive IT management tools. This feature streamlines the management of complex IT infrastructures by offering a range of capabilities, from real-time system monitoring to software update deployment, all facilitated through the implementation of Ansible playbooks." /></p>
                    <p className="card-text mt-1"><FormattedMessage id="products.streamOS_6" defaultMessage="In providing detailed hardware inventory across the entire IT estate, including serial numbers and firmware versions, the web console offers an unprecedented level of transparency. This aids in efficient inventory management and strategic operational planning." /></p>
                    <p className="card-text mt-1"><FormattedMessage id="products.streamOS_7" defaultMessage="By incorporating an Apache Guacamole server, the console extends its functionality to include full desktop control. This feature ensures administrators have total control of their IT environment, regardless of their location or time, significantly extending the scope of IT management operations." /></p>
                    <p className="card-text mt-1"><FormattedMessage id="products.streamOS_8" defaultMessage="In conclusion, StreamOS blends these cutting-edge technologies to provide a secure, flexible, and efficient IT infrastructure management solution. StreamOS simplifies the handling of complex IT landscapes and is ideal for managing a diverse range of IT resources, from extensive networks of client computers to systems employed by remote workers. With its thoughtful design and technological choices, StreamOS stands as a comprehensive solution that boosts and streamlines IT resource management." /></p>
                    <p className="card-text mt-1"><FormattedMessage id="products.streamOS_9" defaultMessage="Additionally, the StreamOS infrastructure and VDI service offer great flexibility. They can be paired with our own VDI solution, or used on-premise with your own infrastructure. StreamOS is optimized for various environments, including Citrix XenApp/XenDesktop and Microsoft Remote Desktop Services, providing a versatile tool for diverse IT landscapes." /></p>
                  </div>
                </div>
              </div>

              <div className="row standalone-row align-items-center no-gutters display-reverse">
                <div className="col-lg-6">
                  <div className="blog-image wow hover-effect fadeInRight text-center image">
                    <GatsbyImage
                      className="img"
                      image={data.img2.childImageSharp.gatsbyImageData}
                      alt="briefcase-icon" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="container d-inline-block">
                    <p className="sub-heading text-center text-orange"><FormattedMessage id="products.streamLab" defaultMessage="StreamLAB" /></p>
                    <h2 className="heading-text text-white"><FormattedMessage id="products.streamLabTitle" defaultMessage="Secure two-way transfer and conversion of medical analyses" /></h2>
                    <p className="card-text mt-5"><FormattedMessage id="products.keyFeatures" defaultMessage="Key features" />:</p>
                    <p className="card-text card-text-list">
                      <br /><span className="list-item"><FormattedMessage id="products.streamLab_1" defaultMessage="Encrypted Bidirectional Transfer between Laboratories and Doctors" /></span>
                      <br /><span className="list-item"><FormattedMessage id="products.streamLab_2" defaultMessage="Adaptive File Conversion for Seamless Integration with Doctors' Software" /></span>
                      <br /><span className="list-item"><FormattedMessage id="products.streamLab_3" defaultMessage="Instant Notifications for New Results, Transfer Errors, and More" /></span>
                      <br /><span className="list-item"><FormattedMessage id="products.streamLab_4" defaultMessage="Automatic Result Printing: The Modern Alternative to Fax" /></span>
                      <br /><span className="list-item"><FormattedMessage id="products.streamLab_5" defaultMessage="Optimized Handling of HL7 Files: Streamlined Norms and Translations" /></span>
                      <br /><span className="list-item"><FormattedMessage id="products.streamLab_6" defaultMessage="Integration of Add-on Files Directly into Results" /></span>
                      <br /><span className="list-item"><FormattedMessage id="products.streamLab_7" defaultMessage="Universal Compatibility: Works on Windows, Mac, and Linux" /></span>
                      <br /><span className="list-item"><FormattedMessage id="products.streamLab_8" defaultMessage="User-Friendly Dashboard: Tailor the Management Console to Your Needs" /></span>
                      <br /><span className="list-item"><FormattedMessage id="products.streamLab_9" defaultMessage="Volume-Based Pricing: The More Analyses, The Lower the Cost Per Unit" /></span>
                      <br /><span className="list-item"><FormattedMessage id="products.streamLab_10" defaultMessage="Economical Maintenance with Cost-Effective Support Services" /></span>
                      <br /><span className="list-item"><FormattedMessage id="products.streamLab_11" defaultMessage="Seamless Transfers and Conversions Between Laboratories" /></span>
                      <br /><span className="list-item"><FormattedMessage id="products.streamLab_12" defaultMessage="Capability to Directly Deliver Results to Patients" /></span>
                      <br /><span className="list-item"><FormattedMessage id="products.streamLab_13" defaultMessage="Capability to Transmit Epidemiological Statistics to Authorities (e.g., SARS-CoV-2)" /></span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="row standalone-row align-items-center no-gutters">
                <div className="col-lg-6">
                  <div className="blog-image wow hover-effect fadeInLeft image">
                    <GatsbyImage
                      className="img"
                      image={data.img3.childImageSharp.gatsbyImageData}
                      alt="covid-image" />
                  </div>
                </div>
                <div className="col-lg-6 stand-img-des">
                  <div className="container d-inline-block">
                    <p className="sub-heading text-center text-orange"><FormattedMessage id="products.streamCAL" defaultMessage="StreamCAL" /></p>
                    <h2 className="heading-text text-white"><FormattedMessage id="products.streamCALTitle" defaultMessage="Digital Bookings for Laboratory Tests" /></h2>
                    <p className="card-text mt-5"><FormattedMessage id="products.streamCAL_1" defaultMessage="StreamCAL is a state-of-the-art web application tailored to modern scheduling needs, merging the worlds of healthcare and business. Its primary mission is to simplify the booking process for individuals and companies alike, making appointments with laboratories a breeze. But the application is more than just a scheduling tool; it's a bridge that seamlessly connects users, businesses, and healthcare establishments." /> </p>
                    <p className="card-text mt-1"><FormattedMessage id="products.streamCAL_2" defaultMessage="For businesses, especially those in the hospitality sector like hotels and resorts, StreamCAL becomes an invaluable asset. These establishments can efficiently manage and coordinate appointments for their guests. This feature proves essential, especially during events that require group health screenings or mandatory tests, such as the SARS-CoV-2 test. The ability to handle bulk bookings while ensuring each client's needs are met is a testament to StreamCAL's versatility." /></p>
                    <p className="card-text mt-1"><FormattedMessage id="products.streamCAL_3" defaultMessage="StreamCAL seamlessly integrates with laboratory management systems, offering significant time savings for labs during mass testing. Additionally, this tight integration allows laboratories to promptly deliver test results directly to the patients, enhancing efficiency and patient experience." /></p>
                  </div>
                </div>
              </div>
              <div className="row standalone-row align-items-center no-gutters display-reverse">
                <div className="col-lg-6">
                  <div className="blog-image wow hover-effect fadeInRight text-center image">
                    <GatsbyImage
                      className="img"
                      image={data.img4.childImageSharp.gatsbyImageData}
                      alt="briefcase-icon" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="container d-inline-block">
                    <p className="sub-heading text-center text-orange"><FormattedMessage id="products.streamVOICE" defaultMessage="StreamVOICE" /></p>
                    <h2 className="heading-text text-center text-white"><FormattedMessage id="products.streamVOICETitle" defaultMessage="External Invoice Management Module" /></h2>
                    <p className="card-text mt-5"><FormattedMessage id="products.keyFeatures" defaultMessage="Key features" />:</p>
                    <p className="card-text card-text-list">
                      <br /><span className="list-item"><FormattedMessage id="products.streamVOICE_1" defaultMessage="Seamless Integration into an existing Laboratory Information System" /></span>
                      <br /><span className="list-item"><FormattedMessage id="products.streamVOICE_2" defaultMessage="Intuitive Web Interface with Customizable Corporate Design" /></span>
                      <br /><span className="list-item"><FormattedMessage id="products.streamVOICE_3" defaultMessage="Customizable Dashboard (Business Intelligence)" /></span>
                      <br /><span className="list-item"><FormattedMessage id="products.streamVOICE_4" defaultMessage="Compatible with Medidata Network (xml 4.5 format / QR invoice)" /></span>
                      <br /><span className="list-item"><FormattedMessage id="products.streamVOICE_5" defaultMessage="Ability to Manage Multiple Departments with Distinct RCC Numbers" /></span>
                      <br /><span className="list-item"><FormattedMessage id="products.streamVOICE_6" defaultMessage="Intuitive Management of Health Insurance Notifications (Multi-User)" /></span>
                      <br /><span className="list-item"><FormattedMessage id="products.streamVOICE_7" defaultMessage="Generation of Invoices in PDF with QR Code" /></span>
                      <br /><span className="list-item"><FormattedMessage id="products.streamVOICE_8" defaultMessage="Management of VAT and Discounts at Position Level" /></span>
                      <br /><span className="list-item"><FormattedMessage id="products.streamVOICE_9" defaultMessage="Generation of Personalized Invoices with Options for Paper Printing or Electronic Transmission (Medidata, Secure Email)" /></span>
                      <br /><span className="list-item"><FormattedMessage id="products.streamVOICE_10" defaultMessage="Generation of Grouped Invoices with Capability to Manage Discounts and VAT at Position Level" /></span>
                      <br /><span className="list-item"><FormattedMessage id="products.streamVOICE_11" defaultMessage="Watermark Management on Invoices (Patient Copy, etc.)" /></span>
                      <br /><span className="list-item"><FormattedMessage id="products.streamVOICE_12" defaultMessage="Editing Invoices (Adding/Deleting Positions or Free Lines)" /></span>
                      <br /><span className="list-item"><FormattedMessage id="products.streamVOICE_13" defaultMessage="History of Analytical Tariffs and related Tariff Positions (OFAS, OFSP, etc.)" /></span>
                      <br /><span className="list-item"><FormattedMessage id="products.streamVOICE_14" defaultMessage="Proactive Management of potential Billing Errors" /></span>
                      <br /><span className="list-item"><FormattedMessage id="products.streamVOICE_15" defaultMessage="Fully Compliant with OFAS Business Rules" /></span>
                      <br /><span className="list-item"><FormattedMessage id="products.streamVOICE_16" defaultMessage="Multiple Bank Accounts Management with Automatic Reconciliation of Payments" /></span>
                      <br /><span className="list-item"><FormattedMessage id="products.streamVOICE_17" defaultMessage="Management of Invoice Reminders and Litigation" /></span>
                      <br /><span className="list-item"><FormattedMessage id="products.streamVOICE_18" defaultMessage="Automated Management of Patient Invoice Copies" /></span></p>
                  </div>
                </div>
              </div>
              <div className="row standalone-row align-items-center no-gutters">
                <div className="col-lg-6">
                  <div className="blog-image wow hover-effect fadeInLeft image">
                    <GatsbyImage
                      className="img"
                      image={data.img5.childImageSharp.gatsbyImageData}
                      alt="covid-image" />
                  </div>
                </div>
                <div className="col-lg-6 stand-img-des">
                  <div className="container d-inline-block">
                    <p className="sub-heading text-center text-orange"><FormattedMessage id="products.streamGO" defaultMessage="StreamGO" /></p>
                    <h2 className="heading-text text-white"><FormattedMessage id="products.streamGOTitle" defaultMessage="Advanced Courier Management and Real-time Delivery & Pickup Tracking Solution" /></h2>
                    <p className="card-text mt-5"><FormattedMessage id="products.streamGODescription" defaultMessage="In an age where swift and effective service is essential, StreamGO revolutionizes transport and delivery mission management. Catering to logistics companies, e-commerce platforms, home delivery services, and medical laboratories, our solution streamlines operations and emphasizes an outstanding client experience." /></p>
                    <p className="card-text card-text-list"><span className="text-white font-weight-500"><FormattedMessage id="products.streamGOKey_1" defaultMessage="Effortless Courier Assignment" /></span>
                      <br /><span className="list-item"><FormattedMessage id="products.streamGO_1" defaultMessage="Intuitive web portal to swiftly assign tasks to available couriers" /></span>
                      <br /><span className="list-item"><FormattedMessage id="products.streamGO_2" defaultMessage="Optimal routes are generated based on shipment details, starting and destination addresses." /></span>
                      <br /><span className="list-item"><FormattedMessage id="products.streamGO_3" defaultMessage="Ensure optimal workload distribution to prevent delays." /></span>
                      <br /><span className="list-item"><FormattedMessage id="products.streamGO_4" defaultMessage="Couriers receive immediate mission details on a dedicated mobile application, ensuring they're equipped with all necessary information for successful deliveries." /></span>
                      <br />
                    </p>
                    <p className="card-text card-text-list"><span className="text-white font-weight-500"><FormattedMessage id="products.streamGOKey_2" defaultMessage="Real-Time Tracking and Updates" /></span>
                      <br /><span className="list-item"><FormattedMessage id="products.streamGO_5" defaultMessage="Monitor your couriers in real-time via our web portal." /></span>
                      <br /><span className="list-item"><FormattedMessage id="products.streamGO_6" defaultMessage="Benefit from real-time geolocation to always pinpoint your couriers and deliveries." /></span>
                      <br /><span className="list-item"><FormattedMessage id="products.streamGO_7" defaultMessage="Offer accurate updates to your clients and manage exceptions promptly." /></span>
                      <br /><span className="list-item"><FormattedMessage id="products.streamGO_8" defaultMessage="Equip your couriers with automated alerts regarding their delivery status, spanning from pick-up to the final drop-off point." /></span>
                      <br />
                    </p>
                    <p className="card-text card-text-list"><span className="text-white font-weight-500"><FormattedMessage id="products.streamGOKey_3" defaultMessage="Advanced Data Analysis" /></span>
                      <br /><span className="list-item"><FormattedMessage id="products.streamGO_9" defaultMessage="Delve into your delivery team's performance metrics." /></span>
                      <br /><span className="list-item"><FormattedMessage id="products.streamGO_10" defaultMessage="Recognize trends, identify areas requiring enhancement, and strategize for optimized operations." /></span>
                      <br />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Products
